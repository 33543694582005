import React, { useState } from 'react';
import Layout from '../../../components/Layout';
import axios from 'axios';

const FAQ = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);

  const onSend = async () => {
    try {
      const res = await axios.post(`https://us-central1-iconic-mariner-146115.cloudfunctions.net/linearsolutions-contact`, {
        name,
        email,
        subject,
        message,
      });
      setSent(true);
    } catch (err) {
      console.log(err);
      setSent(true);
      setError(true);
    }
  };

  return (
    <Layout fullMenu logoUrl="/WI/engineering">
      <article id="main">
        <header>
          <h2>Frequently Asked Questions</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <h4>How do I create an account?</h4>
            <p>
              Select the ”TAKE TEST” option on the homepage - there will be a field at the top of the test page where you can enter your
              email address. Once you successfully pass the test, a user account will automatically be created with the email address you
              used on the test page.
            </p>
            <h4>How do I pay for the course(s) I completed?</h4>
            <p>
              Once you pass the test, you will receive an email saying you passed. Click on the blue "Go to My Account" button at the bottom
              of the email. This will take you to your user dashboard. From here, click on the orange "Pay for Certificate" button. Fill out
              your personal and payment information and you are good to go!
            </p>
            <h4>When will I receive my certificate of completion?</h4>
            <p>Your certificate will be emailed to you instantly upon completion of the course</p>
            <h4>If I lost my certificate, how do I receive another copy?</h4>
            <p>
              Sign into your account, then go to your user dashboard. All course(s) that you have completed will be displayed and you can
              email yourself a copy whenever you’d like.
            </p>

            <h4>When do I have to complete my CE requirements by?</h4>
            <p>Wisconsin Engineers must complete the Professional Conduct and Ethics requirement every 2 years.</p>

            <h4>How many hours am I required to complete to renew my license?</h4>
            <p>Professional Engineers licensed in Wisconsin must have 30 continuing education (CE) credits/hours by July 31st.</p>

            <h4>I completed course 1, but want to take course 2, how do I do this?</h4>
            <p>
              Go ahead and answer the test questions for course 2. Once you pass, the course will be added to your user dashboard. If you
              have already paid for course 1, you will be charged the difference ($15) for completing course 2.
            </p>

            <h4>Does AdvanCE offer group discounts?</h4>
            <p>
              We offer discounts to organizations that have 5 or more individuals looking to complete our courses. Please call 352-207-1827
              if you are interested.
            </p>
          </div>
        </section>
      </article>
    </Layout>
  );
};

export default FAQ;
